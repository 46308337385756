import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ValidationContext } from '../context/context';
import { defaultRoutes, routes } from '../routes/router';


export function HandleAuthRoute(props, usergroups = []) {
    const { component: Component, location, ...rest } = props;
    const [validation] = useContext(ValidationContext);
    const authorised = validation && (usergroups.includes(validation.usergroup));

    return (
        authorised
            ?
            <Route {...rest} render={props => (
                <Component {...props} />
            )} />
            :
            <Redirect to={validation ? defaultRoutes[validation.usergroup] : {
                pathname: routes.login,
                state: {
                    path: location.pathname
                }
            }} />
    );
}