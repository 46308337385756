import React, { useState, useContext } from 'react';
import enIcon from "../assets/icons/en.svg";
import srIcon from "../assets/icons/sr-latin.webp";
import { TranslationContext } from '../context/context';
import { getClasses } from '../utils/utils';

const icons = {
    'en': enIcon,
    'sr-latin': srIcon
}


export default function LanguageSelect({ options = ['en'], language, setLanguage, optionsAlign = 'under', className, label }) {
    const [expand, setExpand] = useState(false);
    const [t] = useContext(TranslationContext);


    return (
        <div
            className={getClasses(className, 'c-language-select')}
            onMouseOver={_ => { setExpand(true) }}
            onMouseLeave={_ => { setExpand(false) }}
        >
            {label && <div className='c-language-select__text'>{label}</div>}
            <div className='c-language-select__selected'>
                <img
                    src={icons[language]}
                    className="c-language-select__option"
                    onClick={_ => { setExpand(!expand) }}
                    alt="language select"
                />
                {expand &&
                    <ul className={getClasses([], "c-language-select__options", [optionsAlign])}>

                        {
                            options.map((lang, i) =>
                                <li key={lang + i}>
                                    <img
                                        className={getClasses([lang === language && "selected"], "c-language-select__option")}
                                        src={icons[lang]}
                                        onClick={_ => { setLanguage(lang); setExpand(false) }}
                                        key={lang}
                                        alt={lang}
                                    />
                                </li>)
                        }
                    </ul>}
            </div>

        </div>);
}
