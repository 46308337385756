import { useContext, useState, useEffect, useRef } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { TranslationContext, ValidationContext } from '../context/context';
import { routes } from '../routes/router';
import { validateUser } from '../services/api.services';
import { appStorage } from '../services/storage.service';
import { debounce, getClasses, getUserGenerals } from '../utils/utils';
import Hamburger from './Hamburger';
import avatarIcon from "../assets/icons/avatar.svg";
import logoutIcon from "../assets/icons/mono-logout.svg";
import LanguageSelect from './LanguageSelect';


export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const isClicked = useRef(false);

  const [validation, setValidation] = useContext(ValidationContext);
  const isAdmin = validation?.usergroup === 'admin';
  const isClient = validation?.usergroup === 'client';

  const [t, language, setLanguage] = useContext(TranslationContext);

  const toggleNav = _ => setToggleMenu(!toggleMenu);
  const collapse = _ => setToggleMenu(false);

  const isMobile = _ => screenWidth < 992;

  useEffect(_ => {
    const handleResize = debounce(() => {
      setScreenWidth(window.innerWidth);
    }, 100);

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }

  }, []);

  useEffect(() => {
    if (isMobile() && toggleMenu) window.addEventListener('click', handleOutsideClick);
    else window.removeEventListener('click', handleOutsideClick);
    return () => {
      window.removeEventListener('click', handleOutsideClick);
    }
  }, [toggleMenu]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(_ => {
    if (!isMobile()) {
      collapse();
    }
  }, [screenWidth])// eslint-disable-line

  function handleOutsideClick() {
    if (!isClicked.current) {
      collapse();
    }
    isClicked.current = false;
  }


  return (
    <nav className={getClasses(
      [
        toggleMenu && isMobile() && 'is-expanded'
      ],
      'c-navbar'
    )} onClick={_ => { isClicked.current = true }}>

      {
        <ul className={getClasses(
          [
            toggleMenu && isMobile() && 'is-expanded'
          ],
          'c-navbar__menu'
        )}>

          {!validation && <li className='c-navbar__menu-item'>
            <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.login} className="c-navbar__link">Prijava</NavLink>
          </li>}

          {
            isAdmin &&
            <li className='c-navbar__menu-item'>
              <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.damageReports} className="c-navbar__link">Prijave kvara</NavLink>
            </li>
          }

          {validation && !isClient && <> <li className='c-navbar__menu-item'>
            <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.workOrders} className="c-navbar__link">Radni Nalozi</NavLink>
          </li>
            <li className='c-navbar__menu-item'>
              <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.specifications} className="c-navbar__link">Specifikacije</NavLink>
            </li></>}

          {
            isAdmin &&
            <>
              {/* <li className='c-navbar__menu-item'>
                <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.recordNew} className="c-navbar__link">Zapisnik</NavLink>
              </li> */}
              <li className='c-navbar__menu-item'>
                <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.users} className="c-navbar__link">Zaposleni</NavLink>
              </li>
              <li className='c-navbar__menu-item'>
                <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.clients} className="c-navbar__link">Klijenti</NavLink>
              </li>
              <li className='c-navbar__menu-item'>
                <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.workOrderNew} className="c-navbar__link">Novi Radni Nalog</NavLink>
              </li>
              <li className='c-navbar__menu-item'>
                <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.damageReportNew} className="c-navbar__link">{t('newDamageReport')}</NavLink>
              </li>
            </>
          }
          {
            isClient &&
            <>
              <li className='c-navbar__menu-item'>
                <NavLink exact activeClassName="is-active" onClick={collapse} to={routes.damageReportNew} className="c-navbar__link">{t('newDamageReport')}</NavLink>
              </li>
            </>
          }
        </ul>
      }

      <LanguageSelect
        options={['en', 'sr-latin']}
        language={language}
        setLanguage={setLanguage}
        className="c-navbar__language"
        label={`${t('selectLanguage')}:`}
        optionsAlign='above' />

      {
        validation &&
        <>
          <NavbarUserInfo className={"c-navbar__user"} user={getUserGenerals(validation)} avatar={avatarIcon}></NavbarUserInfo>
          <div className="c-navbar__logout">
            <div className="c-navbar__logout-text"> {t('logout')}: </div>
            <img
              className="c-navbar__logout-img"
              src={logoutIcon}
              alt="logout"
              onClick={_ => {
                validateUser({ action: "end" });
                setValidation(null);
                appStorage.removeUser();
                <Redirect to={routes.login} />
              }} />
          </div>
        </>
      }
      {
        (isMobile()) &&
        <Hamburger className="c-navbar__hamburger" onClick={e => { e.stopPropagation(); toggleNav(); }} expanded={toggleMenu} />
      }

    </nav>
  );
}

function NavbarUserInfo({ avatar, user, className }) {
  return (
    <div className={getClasses(className, 'c-user-info')}>
      <img src={avatar} alt='Users avatar icon' role="presentation" className='c-user-info__avatar' />
      <div className='c-user-info__user'>{user}</div>
    </div>
  )
}
