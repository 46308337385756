import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { routes } from '../routes/router';
import DialogBox from './DialogBox';

export default function Page({ children }) {
    const history = useHistory();
    const [showErrorDialog, setShowErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    useEffect(() => {
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            const { status, message } = error.response;
            if (status === 404) {
                let displayErrorMsg = "No error message provided!";
                if (typeof message === "string") {
                    displayErrorMsg = message;
                }
                else if (message && typeof message.message === "string") {
                    displayErrorMsg = message.message;
                }
                setShowErrorDialog(true);
                setErrorMessage(displayErrorMsg);
            }
            return Promise.reject(error);
        });

    }, []);

    function onErrorConfirm() {
        history.push(routes.workOrders);
        setShowErrorDialog(false);
    }


    return <div className='c-page'>
        {children}
        <DialogBox show={showErrorDialog} title={"Greska"} onConfirm={onErrorConfirm} description={errorMessage}></DialogBox>
    </div>;
}
