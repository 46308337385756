import React, { useContext, useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom';
import { validateUser } from '../../services/api.services';
import { ValidationContext } from '../../context/context';
import { appStorage } from '../../services/storage.service';
import Loader from '../../components/Loader';
import { routes, defaultRoutes } from '../../routes/router';
import DefaultRoute from '../../routes/DefaultRoute';

export default function LoginPage(props) {
    const [initialCheck, setInitialCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [validation, setValidation] = useContext(ValidationContext);
    const history = useHistory();
    const subscribed = useRef(true);

    useEffect(() => {
        subscribed.current = true;
        if (!validation) {
            const user = appStorage.getUser();
            if (user) {
                setInitialCheck(true);
                setLoading(true);
                validateUser({ action: "check", username: user })
                    .then(res => {
                        if (!subscribed.current) return;
                        setInitialCheck(false);
                        const { username, usergroup, userID, firstname, lastname } = res.data;
                        if (res.data.validated) {

                            setValidation({ usergroup, username, userID, firstname, lastname });

                        }
                    }).catch(_ => {
                        if (!subscribed.current) return;
                        setInitialCheck(false)
                    }).finally(_ => {
                        if (!subscribed.current) return;
                        setLoading(false);
                    });
            }
        }
        else {
            const path = props?.location?.state?.path;
            const redirectTo = path && path !== routes.login ? props.location.state.path : defaultRoutes[validation.usergroup];
            history.push(redirectTo);
        }
        return () => {
            subscribed.current = false;
        }
    }, [validation])// eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (event) => {
        const form = event.target;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            return;
        }

        setLoading(true);
        validateUser({ username, password, action: "start" })
            .then(res => {
                const { data } = res;
                if (data.validated) {
                    const { username, usergroup, userID, firstname, lastname } = res.data;
                    appStorage.setUser(username);
                    setValidation({ usergroup, username, userID, firstname, lastname });
                }
                else {
                    //TODO: replace with proper user message
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response?.data?.message) setMessage(err.response.data.message);
            }).finally(_ => setLoading(false));
    };
    if (validation) return (<DefaultRoute />)
    return (
        <div className="p-login-page" >
            {!initialCheck &&
                <form className="c-login-form" onSubmit={handleSubmit}>
                    <div className="c-login-form__text">Unesite kredencijale kako biste pristupili aplikaciji</div>
                    <label htmlFor="username">Korisničko ime:</label>
                    <input
                        type="text"
                        name="username"
                        className="c-login-form__username"
                        value={username}
                        minLength={4}
                        onChange={e => { setUsername(e.target.value) }}
                        required
                        autoComplete="username"
                    />
                    <label htmlFor="username">Lozinka:</label>
                    <input
                        type="password"
                        name="password"
                        className="c-login-form__password"
                        value={password}
                        minLength={8}
                        onChange={e => { setPassword(e.target.value) }}
                        required
                        autoComplete="current-password"
                    />
                    <input type="submit" className="c-login-form__submit" value="Prijavite se" />
                    <div className="c-login-form__message">{message}</div>
                </form>}
            <Loader loading={loading} />
        </div>
    )
}
