import { getClasses } from "../utils/utils";

export default function Hamburger({ onClick, expanded, className }) {
    return (
        <div onClick={onClick} className={getClasses(
            [
                expanded && "is-expanded",
                className
            ],
            "c-hamburger"
        )
        }>
            <div className="c-hamburger__bar c-hamburger__bar--1"></div>
            <div className="c-hamburger__bar c-hamburger__bar--2"></div>
            <div className="c-hamburger__bar c-hamburger__bar--3"></div>
        </div>
    )
}
