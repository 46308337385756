import React, { Suspense, lazy } from 'react';

//import "bootstrap/dist/css/bootstrap.min.css"  //!Warning: bootstrap is disabled
import "./sass/main.scss";
//ROUTER
import { BrowserRouter, Switch } from "react-router-dom";
//MAIN APP CONTAINER
import AppContainer from './components/AppContainer';
//ROUTES
import PublicRoute from "./routes/PublicRoute";
import AdminRoute from "./routes/AdminRoute";
import WorkerRoute from "./routes/WorkerRoute";
import ClientRoute from "./routes/ClientRoute";
import { routes } from './routes/router';
//CONTEXT
import { TranslationProvider, ValidationProvider } from "./context/context";
//NAVBAR
import Navbar from "./components/Navbar";
//PAGE CONTAINER
import Page from "./components/Page";
//PAGES
import LoginPage from "./pages/login-page/LoginPage";
import Loader from './components/Loader';
//ERROR
import ErrorPage from './pages/error-page/ErrorPage';
import DefaultRoute from './routes/DefaultRoute';


const WorkOrderList = lazy(_ => import("./pages/work-order-list/WorkOrderList"));
const WorkOrder = lazy(_ => import("./pages/work-order/WorkOrder"));
const Record = lazy(_ => import("./pages/record/Record"));
const Specification = lazy(_ => import("./pages/specification/Specification"));
const SpecificationList = lazy(_ => import("./pages/specification-list/SpecificationList"));
const UsersPage = lazy(_ => import("./pages/users-page/UsersPage"));
const ClientsPage = lazy(_ => import("./pages/clients-page/ClientsPage"));
const Print = lazy(_ => import("./prints/Print"));
const DamageReportList = lazy(_ => import("./pages/damage-report-list/DamageReportList"));
const DamageReport = lazy(_ => import("./pages/damage-report/DamageReport"));

function App() {


  return (
    <AppContainer>
      <ValidationProvider>
        <TranslationProvider>
          <BrowserRouter>
            <Navbar />
            <Page>
              <Suspense fallback={Loader({ loading: true })}>
                <Switch>
                  <AdminRoute component={UsersPage} path={routes.users} />
                  <AdminRoute component={ClientsPage} path={routes.clients} />
                  <AdminRoute component={WorkOrder} path={routes.workOrderNew} />
                  <AdminRoute component={WorkOrder} path={routes.workOrderNewFromReport + "/:damageReportId"} />
                  <WorkerRoute component={WorkOrder} path={routes.workOrderView + "/:id"} />
                  <WorkerRoute component={WorkOrderList} path={routes.workOrders} />
                  <WorkerRoute component={Specification} path={routes.specificationEdit + "/:specificationId"} />
                  <WorkerRoute component={Specification} path={routes.specificationNew + "/:workOrderId"} />
                  <WorkerRoute component={SpecificationList} path={routes.specifications} />
                  <ClientRoute component={DamageReport} path={routes.damageReportNew} />
                  <AdminRoute component={DamageReport} path={routes.damageReportView + "/:id"} />
                  <AdminRoute component={DamageReportList} path={routes.damageReports} />
                  <AdminRoute component={Record} path={routes.recordNew + "/:specificationId"} />
                  <AdminRoute component={Record} path={routes.recordEdit + "/:recordId"} />
                  <PublicRoute component={LoginPage} path={routes.login} />
                  <PublicRoute component={Print} path={routes.print} />
                  <PublicRoute component={ErrorPage} path={routes.errorPage} />
                  <DefaultRoute />
                </Switch>
              </Suspense>
            </Page>
          </BrowserRouter>
        </TranslationProvider>
      </ValidationProvider>
    </AppContainer>
  );
}

export default App;
