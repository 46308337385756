import React from 'react';
import ReactDOM from 'react-dom';

export default function Loader({ loading }) {
    if (!loading) return null;
    return ReactDOM.createPortal(
        <div className="c-loader__provider">
            <div className="c-loader">

            </div >
        </div>,
        document.getElementById("portal")
    )
}
