export const routes = {
    errorPage: "/error-page",
    login: "/login",
    workOrders: "/work-orders",
    workOrderNew: "/work-order-new",
    workOrderNewFromReport: "/work-order-new-from-report",
    workOrderView: "/work-order-view",
    specifications: "/specifications",
    specificationNew: "/specification-new",
    specificationEdit: "/specification-edit",
    users: "/users",
    clients: "/clients",
    print: "/print",
    recordNew: "/record-new",
    recordEdit: "/record-edit",
    damageReports: "/damage-reports",
    damageReportNew: "/damage-report-new",
    damageReportView: "/damage-report-view",
}

export const defaultRoutes = {
    worker: routes.workOrders,
    admin: routes.workOrders,
    client: routes.damageReportNew,
}