import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { getClasses } from '../utils/utils';

export default function Modal({ show, children, modifiers }) {
    const scrollPrevented = document.body.classList.contains('u-prevent-scroll');

    useEffect(() => {
        return () => {
            document.body.classList.contains('u-prevent-scroll') && document.body.classList.remove('u-prevent-scroll');
        }
    }, []);

    if (!show) {
        scrollPrevented && document.body.classList.remove('u-prevent-scroll');
        return null;
    };
    !scrollPrevented && document.body.classList.add('u-prevent-scroll');

    return ReactDOM.createPortal(
        <div className="c-modal__provider">
            <div className={getClasses("", "c-modal", modifiers)}>
                <div className="c-modal__body">
                    {/*.k m,;p,ol   - oliverov prvi kod, ne diraj! */}
                    {children}
                </div>
            </div >
        </div>,
        document.getElementById("portal")
    )
}
