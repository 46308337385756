export function getPropertyName(property) {
    switch (property) {
        case "admin": return "Administrator"
        case "worker": return "Radnik"
        case "client": return "Klijent"
        case true: return "Aktivan"
        case false: return "Neaktivan"
        default:
            console.warn("util [getPropertyName] attribute has no match");
            return "";
    }
}

export function getPropertyKey(property) {
    switch (property) {
        case "Administrator": return "admin"
        case "Radnik": return "worker"
        case "Klijent": return "client"
        case "Aktivan": return true
        case "Neaktivan": return false
        default:
            console.warn("util [getPropertyKey] attribute has no match");
            return "";
    }
}

export function getUserGenerals(user) {
    if (typeof user === 'object' && user !== null && Object.keys(user).length > 2)
        return `${zeroPad(user.userID || 0, 4)} ${user.firstname} ${user.lastname}`;
    else if (typeof user === 'string') {
        return user;
    }
    else {
        console.warn("util [getUserGenerals] attribute is not valid");
        return "";
    }
}

export function getDateWithoutTime(dateTime) {
    var date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date.getTime(); // returns timestamp
}

export function getMonth(dateTime) {
    var month = dateTime.split('-')[1];
    return month;
}

export function getDateStringFromTimestamp(timestamp) {
    let dateObject = new Date(timestamp);
    let month = dateObject.getMonth() + 1;
    let date = dateObject.getDate();
    return (date < 10 ? '0' + date : date) + '-' + (month < 10 ? '0' + month : month) + '-' + dateObject.getFullYear();
}

export function zeroPad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export function getClasses(classes, mainClass = "", modifiers) {
    let asString = "";
    if (mainClass && typeof mainClass === "string") {
        asString = mainClass;
        if (Array.isArray(modifiers)) {
            for (let i = 0; i < modifiers.length; i++) {
                const nextModifier = modifiers[i];
                if (nextModifier) asString += ` ${mainClass}--${nextModifier}`;
            }
        }
        else if (typeof modifiers === "string") {
            asString += ` ${mainClass}--${modifiers}`;
        }
    }

    if (Array.isArray(classes)) {
        for (let i = 0; i < classes.length; i++) {
            const nextClass = classes[i];
            if (nextClass) asString += " " + nextClass;

        }
    }
    else if (typeof classes === 'string') {
        asString += (asString ? " " : "") + classes;
    }

    return asString;
}

export function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this,
            args = arguments;

        function later() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        }

        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function throttleLeading(func, delay) {
    var timer = 0;
    return function () {
        if (Date.now() - timer > delay) {
            func();
        }
        timer = Date.now();
    };
}

export function formatDescription(faultsArray) {
    var returnString = "";
    for (var i = 0; i < faultsArray.length; i++) {
        returnString += faultsArray[i].defectData;
        returnString += "\r\n";
    }
    return returnString;
}
