import React, { useEffect } from 'react';
import Modal from '../components/Modal';

export default function DialogBox({ show, confirm = "Yes", cancel = "No", onConfirm, onCancel, title, description, children, }) {

    useEffect(() => {
        if (onCancel) window.addEventListener('keydown', handleOnCancel);
        return () => {
            if (onCancel) window.removeEventListener('keydown', handleOnCancel);
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const handleOnCancel = e => {
        if (e.key === "Escape" && show) onCancel();
    };

    return (
        <Modal show={show}>
            <div className="c-dialog-box">
                <div className="c-dialog-box__header">
                    <span className="c-dialog-box__title">{title}</span>
                </div>
                <div className="c-dialog-box__body">
                    {description ?
                        <div className="c-dialog-box__description">{description}</div> : null
                    }
                    {children}
                    <div className="c-dialog-box__controls">
                        {onConfirm ?
                            <input type="button" className="c-dialog-box__confirm" value={confirm} onClick={onConfirm} /> : null}
                        {onCancel ?
                            <input type="button" className="c-dialog-box__cancel" value={cancel} onClick={onCancel} /> : null}
                    </div>
                </div>
            </div >
        </Modal>
    )
}
