import axios from 'axios';

axios.defaults.withCredentials = true;

//const baseURL = "http://localhost:4000";

//const baseURL = "https://zeleznica.herokuapp.com"; // FOR STAGING TESTING

const baseURL = "https://standard-logistic.org/api"; // FOR MOBILE TESTING

const path = {
    users: "/users",
    session: "/session",
    specification: "/specification",
    damageReport: "/damage-report",
    workOrder: "/work-order",
    record: "/record-route",
    workOrderNumber: "/work-order-number",
    locations: "/locations",
    file: "/file"
}


//USERS
export function createUser(data) {
    return axios.post(baseURL + path.users, data);
}

export function updateUser(id, data) {
    return axios.patch(`${baseURL + path.users}/${id}`, data);
}

export function validateUser(data) {
    return axios.post(baseURL + path.session, data);
}

export function getUsers(data) {
    return axios.get(baseURL + path.users, data);
}


//WORK ORDERS
export function createWorkOrder(data) {
    return axios.post(baseURL + path.workOrder, data);
}

export function getWorkOrderById(id) {
    return axios.get(`${baseURL + path.workOrder}/${id}`);
}

export function getWorkOrderList(query) {
    return axios.get(baseURL + path.workOrder, { params: query });
}

export function getWorkOrderNumber() {
    return axios.get(baseURL + path.workOrderNumber);
}

//DAMAGE REPORTS
export function createDamageReport(data) {
    return axios.post(baseURL + path.damageReport, data);
}

export function updateDamageReportFiles(id, data) {
    return axios.put(`${baseURL + path.damageReport}/${id}`, data);
}

export function getDamageReportById(id) {
    return axios.get(`${baseURL + path.damageReport}/${id}`);
}

export function getDamageReportList(query) {
    return axios.get(baseURL + path.damageReport, { params: query });
}


//SPECIFICATIONS
export function createSpecification(data) {
    return axios.post(baseURL + path.specification, data);
}

export function updateSpecification(id, data) {
    return axios.patch(`${baseURL + path.specification}/${id}`, data);
}

export function getSpecificationList(query) {
    return axios.get(baseURL + path.specification, { params: query });
}

export function getSpecificationById(specificationNumber) {
    return axios.get(baseURL + path.specification + "/" + specificationNumber);
}

//RECORDS
export function createRecord(data) {
    return axios.post(baseURL + path.record, data);
}

export function updateRecord(id, data) {
    return axios.patch(`${baseURL + path.record}/${id}`, data);
}

export function getRecordList(data) {
    return axios.get(baseURL + path.record, data);
}

export function getRecordById(recordNumber) {
    return axios.get(baseURL + path.record + "/" + recordNumber);
}


//LOCATIONS
export function getLocations() {
    return axios.get(baseURL + path.locations);
}

//FILES
export function uploadFile(data, parentId) {
    return axios.post(baseURL + path.file, data, { params: { parentId } });  //TODO: Implement "parentId" logic on backend or remove this param
}

export function getFileById(id) {
    return axios.get(baseURL + path.file + "/" + id);
}
