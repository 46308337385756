import React, { useState, createContext } from 'react';

// Translation

import en from '../assets/locales/en.json';
import srLatin from '../assets/locales/sr-latin.json';
import { appStorage } from '../services/storage.service';

export const TranslationContext = createContext();

function getLocale(lang) {
    switch (lang) {
        case 'sr-latin':
            return srLatin;
        case 'en':
        default:
            return en;
    }
}

export const TranslationProvider = ({ children }) => {
    const [language, setLanguageState] = useState(appStorage.getLanguage() || 'en');

    function t(word, lang = language) {
        if (typeof word !== 'string') {
            return word;
        }
        const translated = getLocale(lang)[word];
        if (translated === undefined) {
            return word;
        }
        return translated;
    }

    function setLanguage(lang) {
        setLanguageState(lang);
        appStorage.setLanguage(lang);
    }

    return (
        <TranslationContext.Provider value={[t, language, setLanguage]}>
            {children}
        </TranslationContext.Provider>
    )
}

// Validation

export const ValidationContext = createContext();

export const ValidationProvider = ({ children }) => {
    const [validation, setValidation] = useState(null);
    return (
        <ValidationContext.Provider value={[validation, setValidation]}>
            {children}
        </ValidationContext.Provider>
    )
}

